import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="container">
        <div className="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Meer<span className="text-orange-600">kt</span>
            </h2>
            <h3 className="text-xl md:text-3xl mt-10">404</h3>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
